import React, { Fragment } from 'react'
import styled from '@emotion/styled'

type BilabelMode = 'normal' | 'center' | 'vertical' | 'right'

const StyledBilabel = styled.div<{ mode: BilabelMode }>`
  display: flex;
  flex-direction: ${({ mode }) =>
    mode === 'vertical' ? 'row-reverse' : 'column'};
  line-height: 1.2;
  text-align: ${({ mode }) => {
    if (mode === 'center') {
      return 'center'
    }
    if (mode === 'right') {
      return 'right'
    }
    return 'left'
  }};
  white-space: nowrap;
`

const Sublabel = styled.div<{ mode: BilabelMode }>`
  font-size: ${10 / 15}em;
  writing-mode: ${({ mode }) =>
    mode === 'vertical' ? 'vertical-rl' : 'horizontal-tb'};
`

const Label = styled.div<{ mode: BilabelMode; nonlatin: boolean }>(
  {
    fontFamily: 'var(--font-family-display)',
    fontSize: `${18 / 15}em`,
  },
  ({ nonlatin }) =>
    nonlatin
      ? {
          letterSpacing: 0,
        }
      : {
          letterSpacing: `${0.15}em`,
          textTransform: 'uppercase',
        },
  ({ mode }) =>
    mode === 'vertical'
      ? {
          writingMode: 'vertical-rl',
        }
      : {
          writingMode: 'horizontal-tb',
        }
)

export interface BilabelProps {
  sublabel?: string
  label: string
  mode?: BilabelMode
  className?: string
}

export const Bilabel: React.FC<BilabelProps> = React.memo(
  ({ sublabel, label, mode = 'normal', className }) => {
    // eslint-disable-next-line no-control-regex
    const nonlatin = /[^\u0000-\u024f]/.test(label)

    return (
      <StyledBilabel className={className} mode={mode}>
        {sublabel ? <Sublabel mode={mode}>{sublabel}</Sublabel> : null}
        <Label mode={mode} nonlatin={nonlatin}>
          {label.split('\n').map((line, i) =>
            i ? (
              <Fragment key={`${i.toString()}-${line}`}>
                <br />
                {line}
              </Fragment>
            ) : (
              <Fragment key={`${i.toString()}-${line}`}>{line}</Fragment>
            )
          )}
        </Label>
      </StyledBilabel>
    )
  }
)
