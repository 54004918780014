import { useState, useLayoutEffect } from 'react'

export const useHeaderDisplayState: () => [boolean, boolean, boolean] = () => {
  const [settled, setSettled] = useState(true)
  const [popup, setPopup] = useState(true)
  const [inherit, setInherit] = useState(false)

  useLayoutEffect(() => {
    let last = window.pageYOffset

    const update = () => {
      if (window.pageYOffset < window.innerHeight * 0.1) {
        setSettled(true)
      } else {
        setSettled(false)
      }

      if (window.pageYOffset < window.innerHeight * 0.1) {
        setPopup(true)
      } else if (window.pageYOffset < last) {
        setPopup(true)
      } else {
        setPopup(false)
      }

      if (window.pageYOffset < window.innerWidth * 0.5) {
        setInherit(false)
      } else {
        setInherit(true)
      }

      last = window.pageYOffset
    }

    window.addEventListener('scroll', update)
    window.addEventListener('resize', update)
    update()

    return () => {
      window.removeEventListener('scroll', update)
      window.removeEventListener('resize', update)
    }
  }, [])

  return [settled, popup, inherit]
}
