import React, { useState, useCallback } from 'react'
import { useTransition, animated, config } from 'react-spring'
import styled from '@emotion/styled'

import { breakpoints } from '../styles/variables'
import { HeaderMenu } from './HeaderMenu'
import { HeaderToggle } from './HeaderToggle'
import { I18nLink } from './I18nLink'
import { useEscapeKey } from '../hooks/useEscapeKey'
import { useHeaderDisplayState } from '../hooks/useHeaderDisplayState'
import { useIsTablet } from '../hooks/useIsTablet'
import LogoSvg from '../images/logo.svg'
import SymbolSvg from '../images/symbol.svg'

type HeaderMode = 'home' | 'product' | 'normal'

const StyledHeader = styled.header<{ mode: HeaderMode }>(
  {
    position: 'relative',
    zIndex: 10,
  },
  ({ mode }) =>
    mode === 'home'
      ? {
          height: 0,
        }
      : {
          height: '5.2rem',

          [`@media (min-width: ${breakpoints.tablet}px)`]: {
            height: 0,
          },
        }
)

interface PopupInnerProps {
  mode: HeaderMode
  inherit: boolean
  active: boolean
  popup: boolean
}

const Popup = styled.div<PopupInnerProps>`
  --color-figure-1000: ${({ mode, inherit }) =>
    mode === 'home' && !inherit ? 'var(--color-ground-1000)' : null};

  background: ${({ active }) =>
    active ? 'var(--color-figure-0050)' : 'var(--color-figure-0000)'};
  display: flex;
  height: 5.2rem;
  justify-content: flex-end;
  position: fixed;
  transform: ${({ popup }) => (popup ? 'translateY(0)' : 'translateY(-100%)')};
  transition: background-color 0.5s, transform 0.25s;
  width: 100%;
  z-index: 1;

  @media (min-width: ${breakpoints.tablet}px) {
    --color-figure-1000: ${({ mode, inherit }) =>
      mode === 'normal' && !inherit ? 'var(--color-ground-1000)' : null};
  }

  @media (prefers-color-scheme: dark) {
    --color-figure-1000: inherit;
  }
`

const Link = animated(I18nLink)

const SymbolLink = styled(Link)`
  align-items: center;
  display: flex;
  height: 5.2rem;
  left: 0;
  padding: 0 2rem;
  position: absolute;

  svg {
    height: auto;
    width: 4rem;
  }

  svg path {
    fill: var(--color-figure-1000);
    transition: fill 0.5s;
  }
`

const LogoLink = styled(Link)`
  left: 0;
  left: 0;
  padding: 2rem 2rem 0 2rem;
  position: absolute;
  position: absolute;

  svg {
    height: 10rem;
    width: auto;
  }

  svg path.type {
    fill: var(--color-figure-1000);
    transition: fill 0.5s;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    svg {
      height: 12rem;
    }
  }
`

export interface HeaderProps {
  titleSublabel?: string
  titleLabel?: string
  mode?: HeaderMode
}

export const Header: React.FC<HeaderProps> = ({
  titleSublabel,
  titleLabel,
  mode = 'normal',
}) => {
  const [active, setActive] = useState(false)
  const toggleActive = useCallback(() => setActive((a) => !a), [])
  const isTablet = useIsTablet()
  const [settled, popup, inherit] = useHeaderDisplayState()
  const logoTransition = useTransition(
    settled && popup && !active && (isTablet || mode === 'home'),
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: config.gentle,
    }
  )
  useEscapeKey(() => setActive(false))

  return (
    <StyledHeader mode={mode}>
      <Popup
        mode={mode}
        inherit={inherit || active}
        active={active}
        popup={popup || active}
      >
        {logoTransition((style, logo) =>
          logo ? (
            // @ts-ignore
            <LogoLink to="/" style={style}>
              <LogoSvg />
            </LogoLink>
          ) : (
            // @ts-ignore
            <SymbolLink to="/" style={style}>
              <SymbolSvg />
            </SymbolLink>
          )
        )}
        <HeaderToggle
          active={active}
          onClick={toggleActive}
          sublabel={titleSublabel}
          label={titleLabel}
        />
      </Popup>
      <HeaderMenu active={active} />
    </StyledHeader>
  )
}
