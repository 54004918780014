import React from 'react'
import styled from '@emotion/styled'

import { HeaderMenuContent } from './HeaderMenuContent'
import { useScrollControl } from '../hooks/useScrollControl'
import { useWindowHeight } from '../hooks/useWindowHeight'

const StyledHeaderMenu = styled.div<{ active: boolean }>(
  {
    background: 'var(--color-background)',
    boxSizing: 'border-box',
    paddingTop: '5.2rem',
    position: 'fixed',
    transition: 'opacity 0.5s',
    width: '100%',
  },
  ({ active }) =>
    active
      ? {
          opacity: 1,
          pointerEvents: 'auto',
        }
      : {
          opacity: 0,
          pointerEvents: 'none',
        }
)

const Scroller = styled.div`
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  height: 100%;
  overflow: scroll;
  overscroll-behavior: contain;
  padding: 1.2rem 4rem 2.4rem 0;
`

interface HeaderMenuProps {
  active: boolean
}

export const HeaderMenu: React.FC<HeaderMenuProps> = ({ active }) => {
  const height = useWindowHeight()
  const scrollerRef = useScrollControl(active)

  return (
    <StyledHeaderMenu active={active} style={{ height }}>
      <Scroller ref={scrollerRef}>
        <HeaderMenuContent active={active} />
      </Scroller>
    </StyledHeaderMenu>
  )
}
