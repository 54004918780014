import React from 'react'
import { Link, GatsbyLinkProps } from 'gatsby'

import { LangContext } from '../contexts/LangContext'

export type I18nLinkProps = Omit<GatsbyLinkProps<unknown>, 'ref'>

export const I18nLink: React.FC<I18nLinkProps> = ({
  children,
  to,
  ...props
}) => (
  <LangContext.Consumer>
    {({ pathPrefix }) => (
      <Link to={to.startsWith('/') ? pathPrefix + to : to} {...props}>
        {children}
      </Link>
    )}
  </LangContext.Consumer>
)
