import React from 'react'
import styled from '@emotion/styled'

import { Bilabel } from './Bilabel'
import { breakpoints } from '../styles/variables'
import { I18nLink } from './I18nLink'
import { LangContext } from '../contexts/LangContext'
import ExternalSvg from '../images/icon-external.svg'
import InstagramSvg from '../images/icon-instagram.svg'
import LogoSvg from '../images/logo.svg'

const StyledFooter = styled.footer`
  height: 100px;
`

const Group = styled.div`
  background: var(--color-figure-0050);
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  padding: 2rem;

  @media (min-width: ${breakpoints.desktop}px) {
    padding-left: calc((100% - 70rem) / 2);
    padding-right: calc((100% - 70rem) / 2);
  }
`

const StoreLink = styled.a`
  align-items: center;
  background: var(--color-figure-0025);
  color: inherit;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  text-decoration: none;
  transition-duration: 0.5s;
  transition-property: background-color, color;

  svg {
    height: auto;
    width: 1rem;
  }

  svg path {
    fill: var(--color-figure-0500);
  }

  @media (min-width: ${breakpoints.desktop}px) {
    padding-left: calc((100% - 70rem) / 2);
    padding-right: calc((100% - 70rem) / 2);
  }

  &:active {
    background-color: var(--color-figure-1000);
    color: var(--color-ground-1000);

    svg path {
      fill: var(--color-ground-0500);
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-figure-1000);
      color: var(--color-ground-1000);
      transition-duration: 0.2s;

      svg path {
        fill: var(--color-ground-0500);
        transition-duration: 0.2s;
      }
    }
  }
`

const Instagram = styled.a`
  align-items: center;
  align-self: start;
  color: inherit;
  display: inline-flex;
  font-size: var(--font-size-s2);
  grid-column: 1;
  grid-row: 1;
  justify-self: start;
  letter-spacing: 0.1em;
  margin-left: -1em;
  margin-top: -1em;
  padding: 1em;
  text-decoration: none;

  svg {
    height: auto;
    margin-right: 1em;
    width: calc(var(--font-size-s2) * 2);
  }

  svg path {
    fill: var(--color-figure-1000);
  }
`

const Logo = styled(I18nLink)`
  grid-column: 2;
  grid-row: 1 / 3;

  svg {
    height: 10rem;
    width: auto;
  }

  svg .type {
    fill: var(--color-figure-1000);
  }

  @media (min-width: ${breakpoints.tablet}px) {
    svg {
      height: 12rem;
    }
  }
`

const Rights = styled.div`
  align-self: end;
  font-size: var(--font-size-sx);
  grid-column: 1;
  grid-row: 2;
  justify-self: start;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`

export const Footer: React.FC = () => (
  <LangContext.Consumer>
    {({ langKey }) => (
      <StyledFooter>
        <StoreLink
          href="https://osuzuyama.co.jp/store/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {langKey === 'ja' ? (
            <Bilabel label="Online Store" sublabel="オンラインストア" />
          ) : (
            <Bilabel label="Online Store" />
          )}
          <ExternalSvg />
        </StoreLink>
        <Group>
          <Instagram
            href="https://www.instagram.com/kurokihonten_osuzuyama/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramSvg />
            FOLLOW US
          </Instagram>
          <Logo to="/">
            <LogoSvg />
          </Logo>
          <Rights>© OSUZUYAMA DISTILLERY</Rights>
        </Group>
      </StyledFooter>
    )}
  </LangContext.Consumer>
)
