import React from 'react'
import { Global, css } from '@emotion/core'
import Helmet from 'react-helmet'

import { breakpoints } from '../styles/variables'
import { Footer } from './Footer'
import { Header, HeaderProps } from './Header'
import { LangContext } from '../contexts/LangContext'
import i18n from '../../i18n.json'

const cssGlobal = css`
  :root {
    --color-background: #fff;

    --color-figure-0000: rgba(0, 0, 0, 0);
    --color-figure-0025: rgba(0, 0, 0, 0.025);
    --color-figure-0050: rgba(0, 0, 0, 0.05);
    --color-figure-0100: rgba(0, 0, 0, 0.1);
    --color-figure-0250: rgba(0, 0, 0, 0.25);
    --color-figure-0500: rgba(0, 0, 0, 0.5);
    --color-figure-1000: rgba(0, 0, 0, 1);

    --color-ground-0000: rgba(255, 255, 255, 0);
    --color-ground-0025: rgba(255, 255, 255, 0.025);
    --color-ground-0050: rgba(255, 255, 255, 0.05);
    --color-ground-0100: rgba(255, 255, 255, 0.1);
    --color-ground-0250: rgba(255, 255, 255, 0.25);
    --color-ground-0500: rgba(255, 255, 255, 0.5);
    --color-ground-1000: rgba(255, 255, 255, 1);

    --font-size-l2: 1.4rem;
    --font-size-l1: 1.2rem;
    --font-size-s1: 0.87rem;
    --font-size-s2: 0.65rem;
    --font-size-sx: 0.33rem;

    --font-family-text: 'Work Sans', 'A1 Gothic R', 'YuGothic', 'Yu Gothic',
      sans-serif;
    --font-family-serif: 'EB Garamond', 'A1 Mincho', 'YuMincho', 'Yu Mincho',
      serif;
    --font-family-display: 'Big Caslon', 'Libre Baskerville', 'A1 Mincho', serif;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --color-background: #111;

      --color-figure-0000: rgba(255, 255, 255, 0);
      --color-figure-0025: rgba(255, 255, 255, 0.025);
      --color-figure-0050: rgba(255, 255, 255, 0.05);
      --color-figure-0100: rgba(255, 255, 255, 0.1);
      --color-figure-0250: rgba(255, 255, 255, 0.25);
      --color-figure-0500: rgba(255, 255, 255, 0.5);
      --color-figure-1000: rgba(255, 255, 255, 1);

      --color-ground-0000: rgba(0, 0, 0, 0);
      --color-ground-0025: rgba(0, 0, 0, 0.025);
      --color-ground-0050: rgba(0, 0, 0, 0.05);
      --color-ground-0100: rgba(0, 0, 0, 0.1);
      --color-ground-0250: rgba(0, 0, 0, 0.25);
      --color-ground-0500: rgba(0, 0, 0, 0.5);
      --color-ground-1000: rgba(0, 0, 0, 1);
    }
  }

  html {
    font-size: 4vmin; /* 15px on 375px-width */

    @media (min-width: ${breakpoints.tablet}px) {
      /* 13px on 768px-width */
      /* 18px on 1476px-width */
      font-size: calc(
        13px + (18 - 13) *
          (
            (100vw - ${breakpoints.tablet}px) /
              (${breakpoints.desktop - breakpoints.tablet})
          )
      );
    }

    @media (min-width: ${breakpoints.desktop}px) {
      font-size: 18px;
    }
  }

  body {
    background: var(--color-background);
    color: var(--color-figure-1000);
    font-family: var(--font-family-text);
    margin: 0;
  }
`

type LayoutProps = Omit<HeaderProps, 'mode'> & {
  langKey: string
  pageTitle?: string
  pageDescription?: string
  headerMode?: HeaderProps['mode']
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  langKey,
  pageTitle,
  pageDescription,
  titleSublabel,
  titleLabel,
  headerMode,
}) => {
  const siteName = langKey === 'ja' ? '尾鈴山蒸留所' : 'Osuzuyama Distillery'
  const title = pageTitle || siteName
  const defaultDescription =
    langKey === 'ja'
      ? // eslint-disable-next-line max-len
        '水の湧く山の奥深くにある蒸留所。大小合わせ三十以上とも言われる尾鈴山瀑布群を擁するこの山の奥深く、森の木々に囲まれ、人里離れたこの地に、豊かで上質な水を求めて造られた蔵。それが、尾鈴山蒸留所です。原料は地元「甦る大地の会」と契約農家が丁寧に栽培収穫したものを使用しています。'
      : ''
  const description = pageDescription || defaultDescription
  const url =
    langKey === 'ja' ? 'https://osuzuyama.co.jp' : 'https://osuzuyama.co.jp/en/'

  return (
    <>
      <Global styles={cssGlobal} />
      <Helmet
        htmlAttributes={{ lang: langKey }}
        defaultTitle={siteName}
        titleTemplate={`%s | ${siteName}`}
        meta={[
          { name: 'description', content: description },

          // ogp (facebook)
          { property: 'og:title', content: title },
          { property: 'og:description', content: description },
          {
            property: 'og:image',
            content: 'https://osuzuyama.co.jp/ogimage.jpg',
          },
          { property: 'og:image:alt', content: siteName },
          { property: 'og:url', content: url },
          { property: 'og:locale', content: langKey },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: siteName },

          // twitter
          { name: 'twitter:title', content: title },
          { name: 'twitter:description', content: description },
          {
            name: 'twitter:image',
            content: 'https://osuzuyama.co.jp/ogimage.jpg',
          },
          { name: 'twitter:image:alt', content: siteName },
          { name: 'twitter:url', content: url },
          { name: 'twitter:card', content: 'summary' },
        ]}
      >
        {pageTitle ? <title>{pageTitle}</title> : null}
        <link
          href="https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=EB+Garamond&family=Work+Sans&display=swap"
          rel="stylesheet"
        />
        <script
          src={`https://typesquare.com/3/tsst/script/ja/typesquare.js?${process.env.TYPESQUARE_ID}&auto_load_font=true`}
        />
      </Helmet>
      <LangContext.Provider
        value={{
          langKey,
          pathPrefix: langKey === i18n.langKeyDefault ? '' : `/${langKey}`,
        }}
      >
        <Header
          titleSublabel={titleSublabel}
          titleLabel={titleLabel}
          mode={headerMode}
        />
        {/* eslint-disable-next-line @typescript-eslint/no-empty-function  */}
        <main onTouchStart={() => {}}>{children}</main>
        <Footer />
      </LangContext.Provider>
    </>
  )
}
