import { useEffect } from 'react'

export const useEscapeKey: (callback: (e?: KeyboardEvent) => void) => void = (
  callback
) => {
  useEffect(() => {
    const update = (e?: KeyboardEvent) => {
      if (e?.key === 'Escape') {
        callback(e)
      }
    }

    document.addEventListener('keyup', update)
    update()

    return () => document.removeEventListener('keyup', update)
  }, [])
}
