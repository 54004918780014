import React from 'react'
import styled from '@emotion/styled'

const StyledHamburger = styled.div`
  height: 1em;
  position: relative;
  width: 1em;
`

interface IconProps {
  active: boolean
  hover: boolean
}

const Opening = styled.div<IconProps>`
  height: 100%;
  position: absolute;
  width: 100%;

  &::before,
  &::after {
    background: var(--color-figure-1000);
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    transition: width ${({ active }) => (active ? 0.1 : 0.2)}s
      ${({ active }) => (active ? 0 : 0.1)}s;
    width: ${({ active, hover }) => {
      if (active) return 0
      if (hover) return 1.3
      return 1
    }}em;
  }

  &::before {
    bottom: calc(50% + ${1 / 6}em);
    transform: translateX(-50%);
  }

  &::after {
    top: calc(50% + ${1 / 6}em);
    transform: translateX(-50%);
  }
`

const Closing = styled.div<IconProps>`
  height: 100%;
  position: absolute;
  width: 100%;

  &::before,
  &::after {
    background: var(--color-figure-1000);
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    top: 50%;
    transition: width ${({ active }) => (active ? 0.2 : 0.1)}s
      ${({ active }) => (active ? 0.1 : 0)}s;
    width: ${({ active, hover }) => {
      if (active && hover) return 1.43
      if (active) return 1.1
      return 0
    }}em;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

interface HambergerProps {
  active: boolean
  hover: boolean
}

export const Hamberger: React.FC<HambergerProps> = ({ active, hover }) => (
  <StyledHamburger>
    <Opening active={active} hover={hover} />
    <Closing active={active} hover={hover} />
  </StyledHamburger>
)
