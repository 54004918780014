import React from 'react'
import { Link } from 'gatsby'
import { useTrail, animated } from 'react-spring'
import styled from '@emotion/styled'

import { Bilabel } from './Bilabel'
import { I18nLink, I18nLinkProps } from './I18nLink'
import { LangContext } from '../contexts/LangContext'
import ExternalSvg from '../images/icon-external.svg'

const NavLink: React.FC<I18nLinkProps> = ({ children, ...props }) => (
  <I18nLink {...props} activeClassName="isActive">
    {children}
  </I18nLink>
)

const StyledHeaderNav = styled.div``

const NavList = styled.ul`
  margin: 0;
  padding: 0;

  &::after {
    background: var(--color-figure-0100);
    content: '';
    display: block;
    height: 1px;
    margin: 1.2rem 0 1.2rem auto;
    width: 2.4rem;
  }

  &:last-child::after {
    content: none;
  }

  > li {
    display: flex;
    justify-content: flex-end;
    list-style: none;
  }

  > li a {
    color: inherit;
    display: block;
    padding: 0.6rem 0;
    position: relative;
    text-decoration: none;
  }

  > li a.isActive::before {
    background: currentColor;
    border-radius: 100%;
    content: '';
    display: block;
    height: 4px;
    left: calc(100% + 1.5em - 2px);
    position: absolute;
    top: calc(50% - 2px);
    width: 4px;
  }

  > li a svg {
    height: 1em;
    left: calc(100% + 1em);
    position: absolute;
    top: calc(50% - 0.5em);
    width: 1em;
  }

  > li a svg path {
    fill: var(--color-figure-0500);
  }
`

const NavListPrimary = styled(NavList)``

const NavListSecondary = styled(NavList)`
  font-size: var(--font-size-s1);
`

interface HeaderMenuContentProps {
  active: boolean
}

export const HeaderMenuContent: React.FC<HeaderMenuContentProps> = ({
  active,
}) => {
  const trail = useTrail(9, {
    y: active ? 0 : -20,
    opacity: active ? 1 : 0,
    config: { tension: 500, friction: 45 },
  })

  return (
    <LangContext.Consumer>
      {({ langKey }) => (
        <StyledHeaderNav>
          <NavListPrimary>
            {/* @ts-ignore */}
            <animated.li style={trail[0]}>
              <NavLink to="/products/whisky/">
                {langKey === 'ja' ? (
                  <Bilabel label="Whisky" mode="right" sublabel="ウイスキー" />
                ) : (
                  <Bilabel label="Whisky" mode="right" />
                )}
              </NavLink>
            </animated.li>
            {/* @ts-ignore */}
            <animated.li style={trail[1]}>
              <NavLink to="/products/spirits/">
                {langKey === 'ja' ? (
                  <Bilabel label="Spirits" mode="right" sublabel="スピリッツ" />
                ) : (
                  <Bilabel label="Spirits" mode="right" />
                )}
              </NavLink>
            </animated.li>
            {/* @ts-ignore */}
            <animated.li style={trail[2]}>
              <NavLink to="/products/shochu/">
                {langKey === 'ja' ? (
                  <Bilabel label="Shochu" mode="right" sublabel="焼酎" />
                ) : (
                  <Bilabel label="Shochu" mode="right" />
                )}
              </NavLink>
            </animated.li>
          </NavListPrimary>
          <NavListPrimary>
            {/* @ts-ignore */}
            <animated.li style={trail[3]}>
              <NavLink to="/philosophy/land/">
                {langKey === 'ja' ? (
                  <Bilabel label="Land" mode="right" sublabel="土地" />
                ) : (
                  <Bilabel label="Land" mode="right" />
                )}
              </NavLink>
            </animated.li>
            {/* @ts-ignore */}
            <animated.li style={trail[4]}>
              <NavLink to="/philosophy/spirit/">
                {langKey === 'ja' ? (
                  <Bilabel label="Spirit" mode="right" sublabel="精神" />
                ) : (
                  <Bilabel label="Spirit" mode="right" />
                )}
              </NavLink>
            </animated.li>
          </NavListPrimary>
          <NavListSecondary>
            {/* @ts-ignore */}
            <animated.li style={trail[5]}>
              <NavLink to="/distiller/">
                {langKey === 'ja' ? (
                  <Bilabel label="Distiller" mode="right" sublabel="会社情報" />
                ) : (
                  <Bilabel label="Distiller" mode="right" />
                )}
              </NavLink>
            </animated.li>
            {langKey === 'ja' ? (
              // @ts-ignore
              <animated.li style={trail[6]}>
                <NavLink to="/distributors/">
                  <Bilabel
                    label="Distributors"
                    mode="right"
                    sublabel="取扱店"
                  />
                </NavLink>
              </animated.li>
            ) : null}
            {/* @ts-ignore */}
            <animated.li style={trail[langKey === 'ja' ? 7 : 6]}>
              <a
                href="https://osuzuyama.co.jp/store/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {langKey === 'ja' ? (
                  <Bilabel
                    label="Online Store"
                    mode="right"
                    sublabel="オンラインストア"
                  />
                ) : (
                  <Bilabel label="Online Store" mode="right" />
                )}
                <ExternalSvg />
              </a>
            </animated.li>
          </NavListSecondary>
          <NavListSecondary>
            {/* @ts-ignore */}
            <animated.li style={trail[langKey === 'ja' ? 8 : 7]}>
              {langKey === 'ja' ? (
                <Link to="/en/">
                  <Bilabel label="English" mode="right" />
                </Link>
              ) : (
                <Link to="/">
                  <Bilabel label="日本語" mode="right" />
                </Link>
              )}
            </animated.li>
          </NavListSecondary>
        </StyledHeaderNav>
      )}
    </LangContext.Consumer>
  )
}
