import { useEffect, useRef, RefObject } from 'react'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'

export const useScrollControl: (
  active: boolean
) => RefObject<HTMLDivElement> = (active) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) return

    if (active) {
      disableBodyScroll(ref.current)
    } else {
      clearAllBodyScrollLocks()
    }
  }, [active])

  return ref
}
