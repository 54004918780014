import React, { useState } from 'react'
import styled from '@emotion/styled'

import { Bilabel } from './Bilabel'
import { Hamberger } from './Hamburger'

const StyledHeaderToggle = styled.button`
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  color: var(--color-figure-1000);
  display: flex;
  font: inherit;
  margin: 0;
  outline: 0;
  padding: 0 2rem;
  transition: color 0.5s;

  @media (hover: hover) {
    cursor: pointer;
  }
`

const Label = styled(Bilabel)<{ active: boolean }>`
  color: ${({ active }) =>
    active ? 'var(--color-figure-0250)' : 'var(--color-figure-1000)'};
  margin-right: 1rem;
  transition: color 0.5s;
`

interface HeaderToggleProps {
  active: boolean
  sublabel?: string
  label?: string
  onClick: () => void
}

export const HeaderToggle: React.FC<HeaderToggleProps> = React.memo(
  ({ active, sublabel, label, onClick }) => {
    const [hover, setHover] = useState(false)

    return (
      <StyledHeaderToggle
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {label ? (
          <Label
            sublabel={sublabel}
            label={label}
            mode="right"
            active={active}
          />
        ) : null}
        <Hamberger active={active} hover={hover} />
      </StyledHeaderToggle>
    )
  }
)
