import { useState, useLayoutEffect } from 'react'

export const useWindowHeight: () => number = () => {
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    const update = () => setHeight(window.innerHeight)

    window.addEventListener('resize', update)
    update()

    return () => window.removeEventListener('resize', update)
  })

  return height
}
